import { Assignments } from "classes/models/assignments/assignment.model";
import { AssignmentsService } from "classes/models/assignments/assignments.service";
import { Student } from "classes/models/students/student.model";
import { StudentsService } from "classes/models/students/students.service";
import {
  collection,
  onSnapshot,
  query,
  Unsubscribe,
  where,
} from "firebase/firestore";
import { SubmittedDocument, SubmittedDocuments } from "types/SubmittedDocument";
import { ClassSessions } from "~/classes/models/class-sessions/class-session.model";
import { ClassSessionsService } from "~/classes/models/class-sessions/class-sessions.service";

import { Classrooms } from "~/classes/models/classrooms/classroom.model";
import { ClassroomsService } from "~/classes/models/classrooms/classrooms.service";

import { catchError, Subscription } from "rxjs";

export const useStudentClassroomData = (userId: string, studentId: string) =>
  defineStore(`/users/${userId}/students/${studentId}/data`, () => {
    const student = ref<Student>();
    const classSessions = ref<ClassSessions>([]);

    const classrooms = ref<Classrooms>([]);
    const assignments = ref<Assignments>([]);
    const submittedDocuments = ref<SubmittedDocuments>([]);

    const classSessionsSubscription = ref<{
      [key: string]: Subscription | undefined;
    }>({});
    const assignmentsSubscription = ref<{
      [key: string]: Subscription | undefined;
    }>({});

    const documentsSubscription = ref<{
      [key: string]: Unsubscribe;
    }>({});

    const isInitialized = ref(false);

    const initializeStudentClassroomData = async () => {
      if (isInitialized.value) return;

      student.value = await StudentsService.get(studentId);

      const classroomIds = student.value.classroomIds;
      classrooms.value = [];

      for (const classroomId of classroomIds) {
        try {
          const classroom = await ClassroomsService.get(classroomId);

          classrooms.value.push(classroom);

          if (classroom.isArchived == true) continue;

          classSessionsSubscription.value[classroomId]?.unsubscribe();

          classSessionsSubscription.value[classroomId] =
            ClassSessionsService.streamClassSessions({
              classroomId,
              userId: useTeacherPesudoStudentLogin().pseudoStudentId
                ? useCurrentUID()
                : undefined,
            })
              .pipe(
                catchError((error) => {
                  return [];
                })
              )
              .subscribe((data) => {
                classSessions.value = data;
              });

          assignmentsSubscription.value[classroomId]?.unsubscribe();
          assignmentsSubscription.value[classroomId] =
            AssignmentsService.streamStudentClassroomAssignments({
              classroomId,
              userId: useTeacherPesudoStudentLogin().pseudoStudentId
                ? useCurrentUID()
                : undefined,
            })
              .pipe(
                catchError((error) => {
                  return [];
                })
              )
              .subscribe((data) => {
                assignments.value = [];
                const newAssignments = data.filter((assignment) => {
                  if (assignment.isArchived == true) return false;

                  // if(assignment.isVisibleToStudents == false) return false;

                  // If the assignment doesn't have an open timestamp then we show it always.
                  if (
                    assignment.openTimestamp == undefined ||
                    assignment.openTimestamp == null
                  )
                    return true;

                  if (assignment.openTimestamp > Date.now()) return false;

                  return true;
                });

                assignments.value = newAssignments;

                assignments.value = useSortAssignments(assignments.value);

                // Reverser the assignments
                assignments.value = assignments.value.reverse();

                for (const assignment of assignments.value) {
                  if (assignment.id == undefined) continue;

                  useStudentAssignmentDataStore(
                    assignment.id
                  ).initializeStudentAssignmentData();
                }
              });

          const db = useFirestore();
          const submittedDocumentsRef = collection(db, `/documents`);
          const submittedDocumentsQuery = query(
            submittedDocumentsRef,
            where("studentId", "==", studentId),
            where("classroomId", "==", classroomId),
            where("state", "not-in", [DocumentState.archived])
          );

          if (documentsSubscription.value[classroomId]) {
            documentsSubscription.value[classroomId]();
          }

          documentsSubscription.value[classroomId] = onSnapshot(
            submittedDocumentsQuery,
            (snapshot) => {
              submittedDocuments.value = snapshot.docs.map((doc) => {
                return {
                  ...doc.data(),
                  id: doc.id,
                } as SubmittedDocument;
              });
            },
            (error) => {}
          );
        } catch (error) {
          // Empty - This is to prevent issues if the classroom no longer exists for the student
        }
      }

      isInitialized.value = true;
    };

    const assignmentDocuments = computed(() => {
      return (assignmentId: string) => {
        const documents = submittedDocuments.value.filter((doc) => {
          return doc.assignmentId === assignmentId;
        });

        documents.sort((a, b) => {
          // sort by lastUpdateTimestamp
          if (a.lastUpdatedTimestamp < b.lastUpdatedTimestamp) return 1;

          if (a.lastUpdatedTimestamp > b.lastUpdatedTimestamp) return -1;

          return 0;
        });

        return documents;
      };
    });

    const isStudentReviewEnabledForClassroom = computed(() => {
      return (classroomId: string) => {
        const classroom = classrooms.value.find(
          (classroom) => classroom.id === classroomId
        );

        if (!classroom) return false;

        return classroom.enableStudentReview;
      };
    });

    const classroomFromId = computed(() => {
      return (classroomId: string) => {
        return classrooms.value.find(
          (classroom) => classroom.id === classroomId
        );
      };
    });

    const hasClassroomResources = computed(() => {
      return classrooms.value.some((classroom) => {
        return (
          classroom.resources.filter((resource) => {
            return resource.isPublic;
          }).length > 0
        );
      });
    });

    return {
      initializeStudentClassroomData,
      classroomFromId,
      classrooms,
      student,
      classSessions,
      assignments,
      submittedDocuments,
      assignmentDocuments,
      isStudentReviewEnabledForClassroom,
      hasClassroomResources,
    };
  });
